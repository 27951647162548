import React, { FunctionComponent } from "react";
import { useDrop } from "react-dnd";
import { Person, IItem } from "./Person";
import "./Unallocated.css";
import { ItemTypes } from "./Question";

interface IUnallocatedProps {
  people: string[];
  onPersonRestored: (name: string) => void;
  canDrag: boolean;
}

export const Unallocated: FunctionComponent<IUnallocatedProps> = ({
  children,
  people,
  onPersonRestored,
  canDrag
}) => {
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.PERSON,
    drop: item => onPersonRestored((item as IItem).name),
    collect: monitor => ({
      isOver: !!monitor.isOver()
    })
  });

  return (
    <div
      id="people"
      style={
        isOver
          ? {
              backgroundColor: "rgba(43, 46, 74, 0.2)"
            }
          : {}
      }
      ref={drop}
    >
      {children}
      {people.map(x => (
        <Person name={x} canDrag={canDrag} />
      ))}
    </div>
  );
};
