import { useEffect, useState, useCallback } from "react";

const spreadsheetId = "1pugoi_WjxqEz1F3-ft_N_xt1b5btaeAk_rkW0CmZQOM";
const range = "Blad1";

const gapiLoaded = (async () => {
  await new Promise(resolve => {
    gapi.load("client", () => resolve());
  });

  await gapi.client.init({
    apiKey: "AIzaSyAVcVBommRIpSHZT4hCNVZMEXOwszCnGXg",
    discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"]
  });
})();

const useSpreadsheetValues = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<gapi.client.sheets.ValueRange | null>(null);

  useEffect(() => {
    const load = async () => {
      await gapiLoaded;

      const values = await gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId,
        range
      });

      setData(values.result);
    };

    load()
      .catch(err => {
        console.error("Error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [loading]);

  return {
    data,
    loading
  };
};

const useSpreadsheetUpdate = () => {
  const [loading, setLoading] = useState(false);

  const updateValues = useCallback((question: string, values: string[]) => {
    const load = async () => {
      setLoading(true);
      const response = await fetch("/.netlify/functions/postToGS", {
        method: "POST",
        body: JSON.stringify({ question, values })
      });
      const json = await response.json();
      console.log("JSON", json);
    };

    return load()
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  return {
    loading,
    updateValues
  };
};

export { useSpreadsheetValues, useSpreadsheetUpdate };
