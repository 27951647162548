import React, { FunctionComponent } from "react";
import "./ListPosition.css";
import { useDrop } from "react-dnd";
import { IItem } from "./Person";
import { ItemTypes } from "./Question";

interface IListPositionProps {
  index: number;
  onChange: (index: number, name: string) => void;
}

export const ListPosition: FunctionComponent<IListPositionProps> = ({
  index,
  children,
  onChange
}) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.PERSON,
    canDrop: () => !children,
    drop: (item: IItem) => {
      onChange(index, item.name);
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop()
    })
  });
  return (
    <div
      ref={drop}
      className="position"
      style={
        isOver && canDrop
          ? {
              backgroundColor: "rgba(43, 46, 74, 0.2)"
            }
          : {}
      }
    >
      <span className="index">{`${index + 1}.`}</span>
      {children}
    </div>
  );
};
