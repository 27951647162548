import React, { useState } from "react";
import "./Person.css";
import { useDrag } from "react-dnd";
import ReactModal from "react-modal";
import { ItemTypes } from "./Question";

interface IPersonProps {
  name: string;
  canDrag: boolean;
}

export interface IItem {
  type: string;
  name: string;
}

export const Person = ({ name, canDrag }: IPersonProps) => {
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.PERSON, name },
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    }),
    canDrag: () => canDrag
  });

  const [showDetails, setDetailsShown] = useState(false);

  return (
    <div
      ref={drag}
      className="person"
      style={{ opacity: isDragging ? 0.5 : 1 }}
      onClick={() => setDetailsShown(!showDetails)}
    >
      <img src={`${name}.jpeg`} className="image" />
      <span className="name">{name}</span>
      <ReactModal
        shouldCloseOnEsc={true}
        className="modal"
        overlayClassName="overlay"
        isOpen={showDetails}
      >
        <img src={`${name}.jpeg`} />
      </ReactModal>
    </div>
  );
};
