import React, { useState } from "react";
import Backend from "react-dnd-html5-backend";
import { ImpulseSpinner } from "react-spinners-kit";
import { useSpreadsheetUpdate } from "./hooks/useSpreadsheetValues";
import { DndProvider } from "react-dnd";
import { Unallocated } from "./Unallocated";
import { ListPosition } from "./ListPosition";
import { Person } from "./Person";
import { IQuestion } from "./App";
import "./Question.css";

export const ItemTypes = {
  PERSON: "person"
};

const people = [
  "Arthur",
  "Bartjan",
  "Bo",
  "Daan",
  "Jaap",
  "Laura",
  "Martijn",
  "Merel",
  "Nadine",
  "Nicole",
  "Veerle",
  "Vera",
  "Wouter"
];

export const Question = ({
  question,
  onSubmit
}: {
  question: IQuestion;
  onSubmit: () => void;
}) => {
  const initial = people.map(x => undefined);
  // const initial = people;
  const [allocations, updateAllocations] = useState<Array<string | undefined>>(
    initial
  );
  const [comments, updateComments] = useState<{ [name: string]: string }>({});

  const { loading, updateValues } = useSpreadsheetUpdate();

  const unallocated = people.filter(x => !allocations.includes(x));

  const onChange = (index: number, name: string) => {
    const newVal = [...allocations];
    const existingIndex = newVal.indexOf(name);
    if (existingIndex > -1) {
      newVal[existingIndex] = undefined;
    }
    newVal[index] = name;

    updateAllocations(newVal);
  };

  const onPersonRestored = (name: string) => {
    const newVal = [...allocations];
    const index = newVal.indexOf(name);

    if (index < 0) {
      return;
    }

    newVal[index] = undefined;
    updateAllocations(newVal);
  };

  const allPositionsFilled = unallocated.length === 0;

  const updateComment = (name: string, comment: string) => {
    updateComments({ ...comments, [name]: comment });
  };

  const submit = async () => {
    const values = allocations.flatMap(x => [
      x || "",
      !!x ? comments[x] || "-" : "-"
    ]);

    await updateValues(question.name, values);
    updateComments({});
    updateAllocations(initial);
    onSubmit();
  };

  return (
    <DndProvider backend={Backend}>
      <div id="questionContainer">
        <div id="header">
          <Unallocated
            people={unallocated}
            onPersonRestored={onPersonRestored}
            canDrag={!loading}
          >
            <h1>{question.question}</h1>
          </Unallocated>
        </div>
        <div id="positions">
          {allocations.map((x, i) => (
            <ListPosition key={i} index={i} onChange={onChange}>
              {!!x ? (
                <React.Fragment>
                  <Person name={x} canDrag={!loading} />{" "}
                  <textarea
                    disabled={loading}
                    onChange={e => updateComment(x, e.target.value)}
                    placeholder="Opmerking"
                    value={comments[x] || ""}
                  ></textarea>
                </React.Fragment>
              ) : null}
            </ListPosition>
          ))}
        </div>

        <div id="submitContainer">
          {loading && (
            <div id="spinner">
              <ImpulseSpinner frontColor="#D3D3D3" backColor="#808080" />
            </div>
          )}
          {!loading && (
            <button
              id="submit"
              disabled={loading || !allPositionsFilled}
              onClick={submit}
            >
              Ga verder
            </button>
          )}
        </div>
      </div>
    </DndProvider>
  );
};
