import React, { useState } from "react";
import "./App.css";
import { Question } from "./Question";

export interface IQuestion {
  name: string;
  question: string;
}

const questions = [
  { name: "2020", question: "Wie gaat het helemaal anders doen in 2020?" },
  {
    name: "handjes",
    question: "Wie heeft de meest losse handjes?"
  },
  { name: "poepen", question: "Wie poept er bij andere mensen thuis?" },

  {
    name: "oor",
    question:
      "Wie zou het eerst een stukje van iemand anders zijn oor afbijten?"
  },

  { name: "cavias", question: "Wie wordt niet aardig gevonden door cavia's?" }
];

const App: React.FC = () => {
  const [question, setQuestion] = useState<IQuestion>(questions[0]);
  const [hasSeenOnboarding, setHasSeenOnboarding] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const goToNextQuestion = () => {
    const index = questions.indexOf(question);
    if (index < 0) {
      return;
    }

    const nextQuestionIndex = index + 1;
    console.log(
      "nextQuestioIndex, questions.length",
      nextQuestionIndex,
      questions.length
    );

    if (nextQuestionIndex >= questions.length) {
      console.log("Setting is done");

      setIsDone(true);
      return;
    }

    setQuestion(questions[nextQuestionIndex]);

    window.scrollTo(0, 0);
  };

  if (!hasSeenOnboarding) {
    return (
      <div className="container">
        <div className="textpanel">
          <h1>Ranking them peeps!</h1>
          <p>
            Hoi! Als je Ranking the Stars wel eens hebt gezien dan weet je dat
            er een mythisch panel van honderd gewone Nederlanders is die BN'ers
            rankt op allerlei vragen. Als jij dit nou altijd al eens hebt willen
            doen heb je geluk! Als je er dan ook nog van houdt om onbekende
            mensen te beoordelen op hun uiterlijk dan heb je dubbel geluk!
          </p>

          <p>
            Je krijgt straks vijf vragen te zien en wij vragen je de dertien
            Onbekende Nederlanders die meespelen op de volgens jou correcte
            volgorde te zetten. Hoe je tot die volgorde komt mag je helemaal
            zelf weten, maar strooi vooal met opmerkingen als jij je keuze toe
            wil lichten.
          </p>

          <p>
            Je zet de mensen op volgorde door ze met je muis - dit werkt niet op
            mobiel, sorry :| - te pakken en op de plek van je keuze te zetten.
            Als je je bedenkt kun je een persoon terugslepen naar een andere
            lege plek of naar de balk bovenaan. Wees gerust, opmerkingen die je
            hebt geplaatst blijven bestaan dus pas de volgorde naar hartelust
            aan.
          </p>
          <p>
            Om te kijken met wie je te maken hebt klik je op een persoon. Je
            krijgt dan de foto van die persoon in het groot te zien.
          </p>

          <p>Gaat wel lukken toch? Succes!</p>
          <button onClick={() => setHasSeenOnboarding(true)}>Let's go!</button>
        </div>
      </div>
    );
  }

  if (isDone) {
    return (
      <div className="container">
        <div className="textpanel">
          <h1>Dankjewel!</h1>
          <p>Je mag dit browservenster nu sluiten. Of niet. </p>
        </div>
      </div>
    );
  }

  return <Question question={question} onSubmit={goToNextQuestion} />;
};

export default App;
